import React, { useEffect, useState } from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";

export default function Home() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch("/api/loans/home");
        const data = await response.json();
        setMetrics(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        setLoading(false); // Stop loading even in case of error
      }
    };

    fetchMetrics();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid2 container spacing={3}>
        <CardItem
          title="Loans Value"
          value={metrics?.totalLoans || 0}
          number={metrics?.totalRepaidLoans || 0}
        />
        <CardItem
          title="Paid Amount"
          value={metrics?.totalRepaidLoans || 0}
          number={metrics?.totalRepaidLoans || 0}
        />
        <CardItem
          title="Overdue Amount"
          value={metrics?.overdueLoans || 0}
          number={metrics?.overdueLoans || 0}
        />
        <CardItem
          title="Defaulted Amount"
          value={metrics?.defaultedLoans || 0}
          number={metrics?.defaultedLoans || 0}
        />

        <Grid2 item size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="h6">Repayment Rate</Typography>
              <Box sx={{ width: "100%", backgroundColor: "#60606030" }}>
                <Box
                  sx={{
                    width: "84%",
                    backgroundColor: "green",
                    color: "white",
                    p: 1,
                  }}
                >
                  <Typography
                    mt={1}
                    textAlign="center"
                    display="block"
                    variant="h5"
                  >
                    84%
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <CardItem
          title="Paid Amount"
          value={metrics?.totalRepaidLoans || 0}
          number={metrics?.totalRepaidLoans || 0}
        />
        <CardItem title="Total Interest" value={metrics?.totalInterest || 0} />
        <CardItem
          title="Total Penalties"
          value={metrics?.totalPenalties || 0}
        />
        <CardItem
          title="Extension Amount"
          value={metrics?.overdueLoans || 0}
          number={metrics?.overdueLoans || 0}
        />

        <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
          <MyPieChart
            title="Loan Issue"
            data={metrics?.loanIssueData.map((item) => ({
              value: item.value,
              label: item.status,
              color:
                item.status === "Approved"
                  ? "green"
                  : item.status === "Rejected"
                  ? "red"
                  : "blue",
            }))}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
          <MyPieChart
            title="Loan Repayment"
            data={metrics?.loanRepaymentData.map((item) => ({
              value: item.value,
              label: item.status,
              color:
                item.status === "Paid"
                  ? "green"
                  : item.status === "Overdue"
                  ? "orange"
                  : "red",
            }))}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
          <MyPieChart
            title="Loan Types"
            data={metrics?.loanTypeData.map((item) => ({
              value: item.value,
              label: item.status === "Approved" ? "Regular" : "Extension",
              color: item.status === "Approved" ? "blue" : "purple",
            }))}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = ({ title, value, number }) => {
  return (
    <Grid2 size={{ xs: 12, md: 3, sm: 6 }}>
      <Card sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Typography color="primary" variant="h4" mt={2}>
            {value.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          </Typography>
          {/* <Typography
            color="secondary"
            display="block"
            textAlign="right"
            variant="subtitle2"
          >
            {number.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          </Typography> */}
        </CardContent>
      </Card>
    </Grid2>
  );
};

const MyPieChart = (props) => {
  const pieParams = {
    height: 300,
    margin: { right: 5 },
    slotProps: {
      legend: {
        direction: "row",
        position: { vertical: "bottom", horizontal: "middle" },
        padding: 0,
        labelStyle: { fontSize: 12 },
      },
    },
  };

  return (
    <Card
      sx={{ borderRadius: "12px", p: 2, boxShadow: "0px 10px 30px #60606040" }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {props.title}
      </Typography>
      <PieChart
        series={[
          {
            data: props.data,
            outerRadius: 100,
            innerRadius: 20,
          },
        ]}
        {...pieParams}
      />
    </Card>
  );
};
