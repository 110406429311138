import React, { useEffect, useState } from "react";
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";

export default function CSDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch("/api/loans/risk-management", {
          credentials: "include",
        });
        const data = await response.json();

        setMetrics(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography variant="h5">No data available</Typography>;
  }

  return (
    <Box sx={{ mt: 8 }}>
      <Typography fontWeight="bold" color="primary" variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid2 container spacing={3}>
        <CardItem title="Customers" value={metrics.totalCustomers} />
        <CardItem title="Loans" value={metrics.totalLoans} />
        <CardItem title="Repaid" value={metrics.repaidLoans} />
        <CardItem title="Extended" value={metrics.extendedLoans} />
        <Grid2 size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="h6">Repayment Rate</Typography>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#60606030",
                }}
              >
                <Box
                  sx={{
                    width: `${
                      (metrics.repaidLoans / metrics.totalLoans) * 100
                    }%`,
                    backgroundColor: "green",
                    color: "white",
                    p: 1,
                  }}
                >
                  <Typography
                    mt={1}
                    textAlign="center"
                    display="block"
                    variant="h5"
                  >
                    {((metrics.repaidLoans / metrics.totalLoans) * 100).toFixed(
                      2
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <MyPieChart title="Loan Issue" data={metrics.loanIssueData || []} />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <MyPieChart
            title="Loan Repayment"
            data={metrics.loanRepaymentData || []}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <MyPieChart title="Loan Types" data={metrics.loanTypeData || []} />
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = (props) => {
  return (
    <Grid2 size={{ md: 3, xs: 12, sm: 2 }}>
      <Card sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}>
        <CardContent>
          <Typography variant="subtitle" gutterBottom>
            {props.title}
          </Typography>
          <Typography color="primary" variant="h4" mt={2}>
            {props.value?.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
};

const MyPieChart = (props) => {
  const [data, setData] = useState([]);
  const pieParams = {
    height: 300,
    margin: { right: 5 },
    slotProps: {
      legend: {
        direction: "row",
        position: { vertical: "bottom", horizontal: "middle" },
        padding: 0,
        labelStyle: {
          fontSize: 12,
        },
      },
    },
  };
  useEffect(() => {
    // Map the provided data into the format expected by the PieChart
    const mappedData = props.data.map((item, index) => ({
      id: index,
      label: item.status,
      value: parseInt(item.value), // Ensure 'value' is correctly parsed
    }));

    setData(mappedData);
  }, [props.data]);

  return (
    <Card
      sx={{
        borderRadius: "12px",
        p: 2,
        boxShadow: "0px 10px 30px #60606040",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {props.title}
      </Typography>
      <PieChart
        series={[
          {
            data: data, // Use the mapped data here
            outerRadius: 100,
            innerRadius: 20,
          },
        ]}
        {...pieParams}
      />
    </Card>
  );
};
