import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0033A0", // Replace this with your desired primary color
    },
    secondary: {
      main: "#FF6600", // Replace this with your desired primary color
    },
  },
});

export default theme;
