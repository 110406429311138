import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { Email, Message, Phone, WhatsApp } from "@mui/icons-material";

export default function CSCustomers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true${
        status === "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, status]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const checkOverdue = (loans) => {
    return loans.some((loan) => loan.overdueDays > 0);
  };

  // Function to check if any loan is defaulted (more than 60 days since repayment)
  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Paid" && daysSinceRepayment > 60;
    });
  };

  const handleOpenDetails = (user) => {
    setSelectedUser(user);
    setOpenDetails(true);
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}></Box>
          <FormControl>
            <InputLabel size="small">Type</InputLabel>
            <Select
              label="Type"
              size="small"
              onChange={(e) => {
                setOffset(0);
                setStatus(e.target.value);
              }}
              value={status}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="true">Premium</MenuItem>
              <MenuItem value="false">Regular</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>ID No</TableCell>
                  <TableCell>Loans</TableCell>
                  <TableCell>Overdue</TableCell>
                  <TableCell>Limit</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((item, index) => {
                    const overdue = checkOverdue(item.Loans); // Check overdue loans

                    return (
                      <TableRow key={index}>
                        <TableCell
                          padding="checkbox"
                          onClick={() => handleOpenDetails(item)}
                        >
                          <Chip label={offset * 10 + index + 1} />
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          <Chip
                            color={item.isVerified ? "success" : "warning"}
                            label={item.isVerified ? "Premium" : "Regular"}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {item.firstName} {item.lastName}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {item.idCardNumber}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {item.Loans.length}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          <Chip
                            color={overdue ? "error" : "success"}
                            label={overdue ? "Overdue" : "No Overdue"}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {(
                            item.CreditScore?.creditLimit - 0
                          ).toLocaleString() ?? "N/A"}
                        </TableCell>{" "}
                        <TableCell padding="checkbox">
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <IconButton
                              variant="contained"
                              color="primary"
                              size="small"
                              title={item.phoneNumber}
                              onClick={() =>
                                window.open(
                                  `tel:+${item.phoneNumber
                                    .slice(1)
                                    .replace("+", "")}`,
                                  "_self"
                                )
                              }
                            >
                              <Phone />
                            </IconButton>
                            <IconButton
                              variant="contained"
                              color="secondary"
                              size="small"
                              title={item.phoneNumber}
                              onClick={() =>
                                window.open(
                                  `sms:+${item.phoneNumber
                                    .slice(1)
                                    .replace("+", "")}`,
                                  "_self"
                                )
                              }
                            >
                              <Message />
                            </IconButton>
                            <IconButton
                              variant="contained"
                              color="success"
                              size="small"
                              title={item.phoneNumber}
                              onClick={() =>
                                window.open(
                                  `https://wa.me/+${item.phoneNumber
                                    .slice(1)
                                    .replace("+", "")}`,
                                  "_blank"
                                )
                              }
                            >
                              <WhatsApp />
                            </IconButton>
                            <IconButton
                              variant="contained"
                              color="error"
                              size="small"
                              title={item.email}
                              onClick={() =>
                                window.open(`mailto:${item.email}`, "_self")
                              }
                            >
                              <Email />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>No Customers Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {selectedUser && (
          <Dialog
            open={openDetails}
            onClose={() => setOpenDetails(false)}
            fullWidth
          >
            <DialogTitle>User Details</DialogTitle>
            <Divider />
            <DialogContent>
              <Stack spacing={1}>
                <Typography variant="h6">{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
                <Typography variant="body2">
                  ID: {selectedUser.idCardNumber}
                </Typography>
                <Typography variant="body2">
                  Email: {selectedUser.email}
                </Typography>
                <Typography variant="body2">
                  Phone:{" "}
                  {selectedUser.phoneNumber
                    .replace("+", "")
                    .replace("254", "0")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Credit Limit:{" "}
                  <strong>
                    {(selectedUser.CreditScore?.creditLimit - 0).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </strong>
                </Typography>
                <Typography sx={{ pt: 2 }} variant="h6" fontSize="medium">
                  Recent Loans
                </Typography>
                <Divider />
                {selectedUser.Loans.map((loan, index) => (
                  <Card
                    key={index}
                    sx={{
                      mt: 1,
                      mb: 1,
                      boxShadow: "0px 2px 16px #60606040",
                      p: 1,
                    }}
                  >
                    <Box display="flex" gap={2}>
                      <Chip
                        sx={{ fontSize: "small" }}
                        label={loan.repaymentDate}
                      />
                      <Typography
                        color="primary"
                        fontWeight="bold"
                        flexGrow={1}
                        margin="auto"
                      >
                        {(loan.paymentAmount - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography margin="auto" variant="body2">
                        {loan.overdueDays} days overdue
                      </Typography>
                      <Chip
                        color={loan.status == "Paid" ? "success" : "warning"}
                        label={loan.status}
                      />
                    </Box>
                  </Card>
                ))}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDetails(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
