import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  ButtonGroup,
  Divider,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import dayjs from "dayjs";
import { DateRange, Money, SupportAgent } from "@mui/icons-material";
import { CurrencyDollar, User } from "@phosphor-icons/react";

export default function DCLoans() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Approved");

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/loans?offset=${
        offset * 10
      }&includeUsers=true&includePayments=true&includeFollowUps=true&${
        selected == "Approved"
          ? `loanStatus=Approved&loanStatus=Extension`
          : `loanStatus=${selected}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/loans?${column}=${value}&includeUsers=true&includePayments=trueincludeFollowUps=true&${
          selected == "Approved"
            ? `loanStatus=Approved&loanStatus=Extension`
            : `loanStatus=${selected}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: { xs: 2, md: 1 } }}>
          <Button
            variant={selected === "Approved" ? "contained" : "outlined"}
            onClick={() => setSelected("Approved")}
            color="success"
            sx={{ textTransform: "capitalize" }}
          >
            Active
          </Button>
          <Button
            variant={selected === "Overdue" ? "contained" : "outlined"}
            onClick={() => setSelected("Overdue")}
            color="warning"
            sx={{ textTransform: "capitalize" }}
          >
            Overdue
          </Button>
          <Button
            variant={selected === "Defaulted" ? "contained" : "outlined"}
            onClick={() => setSelected("Defaulted")}
            color="error"
            sx={{ textTransform: "capitalize" }}
          >
            Defaulted
          </Button>
        </Box>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}>
            <Typography variant="h5"></Typography>
          </Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {selected == "Management" ? (
          <Box></Box>
        ) : (
          <>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">SN</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Loan</TableCell>
                      <TableCell>Order No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Agent</TableCell>
                      <TableCell>Overdue</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data?.data?.length > 0 ? (
                      data.data.map((item, index) => {
                        return (
                          <LoanBody
                            key={index}
                            index={index}
                            offset={offset}
                            item={item}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10}>No Loans Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box mt={1}>
              {data && (
                <Pagination
                  count={Math.ceil(data.total / 10)}
                  page={offset + 1}
                  onChange={(e, value) => setOffset(value - 1)}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

const LoanBody = ({ item, index, offset, refresh, setRefresh }) => {
  const [user, setUser] = useState(null);
  const [agent, setAgent] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);

  const [loading, setLoading] = useState(false);
  const [aloading, setALoading] = useState(false);
  const [agents, setAgents] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (item) {
      fetch(`/api/users/me/${item.userId}`, { credentials: "include" })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setUser(data);
        })
        .catch((e) => {});
      if (item.assignedAgentId) {
        fetch(`/api/auth/${item.assignedAgentId}`, { credentials: "include" })
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error();
          })
          .then((data) => {
            setAgent(data);
          })
          .catch((e) => {});
      }
    }
  }, [item, refresh]);

  function getAgents() {
    if (item) {
      fetch(`/api/auth`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setLoading(false);
          setAgents(data);
          setOpenAssignDialog(true);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  const handleAssignAgent = () => {
    if (!selectedLoan || !selectedAgentId) return;
    setALoading(true);
    fetch(`/api/loans/${selectedLoan.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assignedAgentId: selectedAgentId }),
    })
      .then((res) => {
        if (res.ok) {
          setError("Agent assignment succefful!");
          setTimeout(() => {
            setOpen(false);
            setRefresh(!refresh); // Refresh loan data
            setOpenAssignDialog(false); // Close the dialog
          }, 1000);
        } else {
          setError("Agent assignment failed!");
        }
      })
      .catch((error) => {
        setError("Agent assignment failed!");
      })
      .finally(() => {
        setALoading(false);
      });
  };

  return (
    <>
      <TableRow
        onClick={() => {
          setOpen(true);
          setSelectedLoan(item);
        }}
        key={index}
      >
        <TableCell padding="checkbox">
          <Chip label={offset * 10 + index + 1} />
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {user?.firstName ? user?.firstName : "..."}{" "}
            {user?.lastName ? user?.lastName : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color={
                item.overdueDays == 0 && item.status != "Defaulted"
                  ? "success"
                  : item.overdueDays < 60 && item.status != "Defaulted"
                  ? "warning"
                  : "error"
              }
              label={
                "KSh " +
                (item.remainingAmount - 0).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item.orderNumber}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip label={item.repaymentDate} />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {agent?.Name ? agent?.Name : "..."} -{" "}
            {agent?.Phone ? agent?.Phone : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color={
                item.overdueDays == 0 && item.status != "Defaulted"
                  ? "success"
                  : item.overdueDays < 60 && item.status != "Defaulted"
                  ? "warning"
                  : "error"
              }
              label={item.overdueDays}
            />
          </Typography>
        </TableCell>
      </TableRow>
      <LoanDetailsPopup
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        getAgents={() => {
          getAgents();
        }}
        agent={agent}
        user={user}
        loan={item}
        loading={loading}
        setLoading={setLoading}
      />
      {agents && (
        <AssignAgentDialog
          open={openAssignDialog}
          onClose={() => setOpenAssignDialog(false)}
          agents={agents}
          selectedAgentId={selectedAgentId}
          setSelectedAgentId={setSelectedAgentId}
          onSubmit={handleAssignAgent}
          error={error}
          loading={aloading}
        />
      )}
    </>
  );
};

const LoanDetailsPopup = ({
  open,
  onClose,
  loan,
  user,
  agent,
  getAgents,
  loading,
  setLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Loan Details</DialogTitle>
      <DialogContent dividers>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <User size={64} color="#0033A0" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.email}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <CurrencyDollar color="#0033A0" size={64} />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              <Chip
                color={
                  loan.overdueDays == 0 && loan.status != "Defaulted"
                    ? "success"
                    : loan.overdueDays < 60 && loan.status != "Defaulted"
                    ? "warning"
                    : "error"
                }
                size="small"
                label={
                  "KSh " +
                  (loan.remainingAmount - 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }
              />
            </Typography>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body2"
              gutterBottom
            >
              Date Due: <Chip size="small" label={loan.repaymentDate} />
            </Typography>
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body2"
              gutterBottom
            >
              Days Overdue:{" "}
              <Chip
                size="small"
                color={
                  loan.overdueDays == 0
                    ? "success"
                    : loan.overdueDays < 60
                    ? "warning"
                    : "error"
                }
                label={loan.overdueDays}
              />
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <SupportAgent
              color="warning"
              sx={{ fontSize: "64px", margin: "auto" }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Grid2 size={{ xs: 12, md: 10 }}>
              <Typography variant="body2" gutterBottom>
                {agent?.Name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {agent?.Phone}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {agent?.Email}
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Extensions</Typography>
            <Divider />
            {loan.extensions.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.date} />
                  <Typography variant="body2">
                    KSh{" "}
                    {(item.amount - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Payments</Typography>
            <Divider />
            {loan.payments.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.paymentDate} />
                  <Typography variant="body2">
                    KSh{" "}
                    {(item.amountPaid - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Follow Ups</Typography>
            <Divider />
            {loan.LoanFollowUps.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",

                    mt: 2,
                  }}
                >
                  <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                    <Chip
                      size="small"
                      label={
                        item.createdAt?.toLocaleString()?.split("T")[0] +
                        " " +
                        item.createdAt
                          ?.toLocaleString()
                          ?.split("T")[1]
                          ?.slice(0, 5)
                      }
                    />
                    <Typography
                      sx={{ m: "auto", display: "block" }}
                      variant="body2"
                    >
                      <Chip
                        size="small"
                        color={
                          item.feedback == "Abusive"
                            ? "error"
                            : item.feedback == "Responsive" ||
                              item.feedback == "Promised to Pay"
                            ? "success"
                            : "warning"
                        }
                        label={item.feedback}
                      />
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography
                    sx={{ mt: "8px", fontStyle: "italic" }}
                    variant="body1"
                  >
                    {item.feedbackReason}
                  </Typography>
                </Card>
              );
            })}
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
        <Button
          onClick={() => {
            getAgents();
            setLoading(true);
          }}
          variant="contained"
          color="primary"
        >
          {loading ? "Fetching..." : "Assign Agent"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AssignAgentDialog = ({
  open,
  onClose,
  agents,
  selectedAgentId,
  setSelectedAgentId,
  onSubmit,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Assign Agent</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Agent</InputLabel>
          <Select
            label="Agent"
            value={selectedAgentId}
            onChange={(e) => setSelectedAgentId(e.target.value)}
          >
            {agents.data.map((agent) => (
              <MenuItem key={agent.UserID} value={agent.UserID}>
                {agent.Name} - {agent.Phone}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained">
          {loading ? "Assigning..." : "Assign"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
