import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  ButtonGroup,
  Divider,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";

export default function RMApplications() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Pending");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    fetchApplications();
  }, [offset, refresh, selected, status]);

  useEffect(() => {
    setInterval(() => {
      if (selected == "Pending") {
        fetchApplications();
      }
    }, 60000);
  }, []);

  function fetchApplications() {
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true&loanStatus=${selected}${
        status == "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true&loanStatus=${selected}${
          status == "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const checkOverdue = (loans) => {
    return loans.some((loan) => loan.overdueDays > 0);
  };

  // Function to check if any loan is defaulted (more than 60 days since repayment)
  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Paid" && daysSinceRepayment > 60;
    });
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <ButtonGroup sx={{ mb: 2 }}>
          <Button
            variant={selected === "Pending" ? "contained" : "outlined"}
            onClick={() => {
              setSelected("Pending");
            }}
            color="success"
            sx={{ textTransform: "capitalize" }}
          >
            Pending
          </Button>

          <Button
            variant={selected === "Rejected" ? "contained" : "outlined"}
            onClick={() => {
              setSelected("Rejected");
            }}
            color="error"
            sx={{ textTransform: "capitalize" }}
          >
            Rejected
          </Button>
          <Button
            variant={selected === "Approved" ? "contained" : "outlined"}
            onClick={() => {
              setSelected("Approved");
            }}
            color="primary"
            sx={{ textTransform: "capitalize" }}
          >
            Approved
          </Button>
        </ButtonGroup>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}></Box>
          <FormControl>
            <InputLabel size="small">Type</InputLabel>
            <Select
              label="Type"
              size="small"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="true">Premium</MenuItem>
              <MenuItem value="false">Regular</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>ID No</TableCell>
                  <TableCell>Loans</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Limit</TableCell>
                  <TableCell>Requested</TableCell>
                  {(selected == "Pending" || selected == "Rejected") && (
                    <TableCell>Actions</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data?.data?.length > 0 ? (
                  data.data.map((item, index) => {
                    const overdue = checkOverdue(item.Loans); // Check overdue loans
                    const defaulted = checkDefaulted(item.Loans); // Check defaulted loans
                    const latestLoan = item.Loans[0];

                    return (
                      <AppBody
                        item={item}
                        latestLoan={latestLoan}
                        selected={selected}
                        index={index}
                        overdue={overdue}
                        defaulted={defaulted}
                        offset={offset}
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarSeverity={setSnackbarSeverity}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setSnackbarOpen={setSnackbarOpen}
                        setSelectedUser={setSelectedUser}
                        setOpenDetails={setOpenDetails}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>No Customers Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      {selectedUser && (
        <Dialog
          open={openDetails}
          onClose={() => setOpenDetails(false)}
          fullWidth
        >
          <DialogTitle>User Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={1}>
              <Typography variant="h6">{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
              <Typography variant="body2">
                ID: {selectedUser.idCardNumber}
              </Typography>
              <Typography variant="body2">
                Email: {selectedUser.email}
              </Typography>
              <Typography variant="body2">
                Phone:{" "}
                {selectedUser.phoneNumber.replace("+", "").replace("254", "0")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Credit Limit:{" "}
                <strong>
                  {(selectedUser.CreditScore?.creditLimit - 0).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </strong>
              </Typography>
              <Typography sx={{ pt: 2 }} variant="h6" fontSize="medium">
                Recent Loans
              </Typography>
              <Divider />
              {selectedUser.Loans.map((loan, index) => (
                <Card
                  key={index}
                  sx={{
                    mt: 1,
                    mb: 1,
                    boxShadow: "0px 2px 16px #60606040",
                    p: 1,
                  }}
                >
                  <Box display="flex" gap={2}>
                    <Chip
                      sx={{ fontSize: "small" }}
                      label={loan.repaymentDate}
                    />
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      flexGrow={1}
                      margin="auto"
                    >
                      {(loan.paymentAmount - 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                    <Typography margin="auto" variant="body2">
                      {loan.overdueDays} days overdue
                    </Typography>
                    <Chip
                      color={loan.status == "Paid" ? "success" : "warning"}
                      label={loan.status}
                    />
                  </Box>
                </Card>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDetails(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const AppBody = ({
  item,
  latestLoan,
  selected,
  index,
  overdue,
  defaulted,
  offset,
  setSnackbarMessage,
  setSnackbarSeverity,
  refresh,
  setRefresh,
  setSnackbarOpen,
  setSelectedUser,
  setOpenDetails,
}) => {
  const [rloading, setRLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  // Function to update loan status
  const updateLoanStatus = async (loanId, status) => {
    if (status == "Approved") setUpdating(true);
    else setRLoading(true);
    try {
      await fetch(`/api/loans/${loanId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
        credentials: "include",
      });
      setSnackbarMessage(`Loan ${status}`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setRefresh(!refresh); // Refresh data after update
    } catch (error) {
      setSnackbarMessage("Failed to update loan status");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Failed to update loan status:", error);
    } finally {
      setUpdating(false);
      setRLoading(false);
    }
  };
  const handleOpenDetails = (user) => {
    setSelectedUser(user);
    setOpenDetails(true);
  };
  return (
    <TableRow key={index}>
      <TableCell padding="checkbox" onClick={() => handleOpenDetails(item)}>
        <Chip label={offset * 10 + index + 1} />
      </TableCell>
      <TableCell onClick={() => handleOpenDetails(item)}>
        <Chip
          color={item.isVerified ? "success" : "warning"}
          label={item.isVerified ? "Premium" : "Regular"}
        />
      </TableCell>
      <TableCell onClick={() => handleOpenDetails(item)}>
        {item.firstName} {item.lastName}
      </TableCell>
      <TableCell>{item.idCardNumber}</TableCell>
      <TableCell onClick={() => handleOpenDetails(item)}>
        <Typography sx={{ display: "block" }} variant="body2">
          {item.Loans.length}
        </Typography>
        <Divider sx={{ my: "3px" }} />
        <Typography
          sx={{
            display: "block",
            color: overdue ? "orange" : "green",
            fontStyle: "italic",
          }}
          variant="body2"
          onClick={() => handleOpenDetails(item)}
        >
          {overdue ? "Overdue" : "No Overdue"}
        </Typography>
        <Divider sx={{ my: "3px" }} />
        <Typography
          sx={{
            display: "block",
            color: defaulted ? "orange" : "green",
            fontStyle: "italic",
          }}
          variant="body2"
          onClick={() => handleOpenDetails(item)}
        >
          {defaulted ? "Defaulted" : "No Default"}
        </Typography>
      </TableCell>
      <TableCell color="primary">
        {latestLoan
          ? new Date(latestLoan.createdAt).toLocaleString("en-US", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "N/A"}
      </TableCell>
      <TableCell onClick={() => handleOpenDetails(item)}>
        {(item.CreditScore?.creditLimit - 0).toLocaleString() ?? "N/A"}
      </TableCell>
      <TableCell color="primary">
        {latestLoan ? latestLoan.amount.toLocaleString() : "N/A"}
      </TableCell>

      {selected != "Approved" &&
        selected != "Paid" &&
        selected != "Extension" && (
          <>
            {selected == "Pending" ? (
              <TableCell>
                <Button
                  sx={{
                    display: "block",
                    mb: 1,
                    width: "100%",
                  }}
                  variant="contained"
                  onClick={() => updateLoanStatus(latestLoan.id, "Approved")}
                >
                  {updating ? <CircularProgress /> : "Approve"}
                </Button>
                <Button
                  sx={{ display: "block", width: "100%" }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => updateLoanStatus(latestLoan.id, "Rejected")}
                >
                  {rloading ? <CircularProgress /> : "Reject"}
                </Button>
              </TableCell>
            ) : (
              <TableCell>
                <Button
                  sx={{
                    display: "block",
                    mb: 1,
                    width: "100%",
                  }}
                  variant="contained"
                  color="warning"
                  onClick={() => updateLoanStatus(latestLoan.id, "Pending")}
                >
                  {updating ? <CircularProgress /> : "Revoke"}
                </Button>
              </TableCell>
            )}
          </>
        )}
    </TableRow>
  );
};
