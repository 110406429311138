import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Alert,
  Divider,
  Grid2,
} from "@mui/material";

export default function EnquiryDialog({ open, onClose }) {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validateName = (name) => name.trim().length > 1;
  const validateEmail = (email) => email.trim().length > 5; // Basic email format validation
  const validatePhone = (phone) => /^0\d{9}$/.test(phone);
  const validateMessage = (message) => message.trim().length > 10;

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    // Validate all fields
    if (!validateName(name)) {
      setError("Name must be at least 2 characters long.");
      setLoading(false);
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid ID Number");
      setLoading(false);
      return;
    }
    if (!validatePhone(phone)) {
      setError("Phone number must be 10 digits and start with 0.");
      setLoading(false);
      return;
    }
    if (!validateMessage(message)) {
      setError("Message must be at least 10 characters long.");
      setLoading(false);
      return;
    }

    const data = {
      Title: title,
      Name: name,
      IDNo: email,
      Phone: phone,
      Message: message,
    };

    try {
      const response = await fetch("/api/enquiries/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSuccess("Enquiry sent successfully!");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      } else {
        throw new Error("Failed to send enquiry");
      }
    } catch (err) {
      setError("Failed to send enquiry, please try again later.");
    } finally {
      setLoading(false);
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle color="primary">Record an Enquiry</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Subject"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={title && !validateName(title)}
            helperText={
              title && !validateName(title)
                ? "Title must be at least 5 characters long."
                : ""
            }
            sx={{ marginBottom: 2 }}
            required
          />
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={name && !validateName(name)}
            helperText={
              name && !validateName(name)
                ? "Name must be at least 2 characters long."
                : ""
            }
            sx={{ marginBottom: 2 }}
            required
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="ID No"
                fullWidth
                type="number"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={email && !validateEmail(email)}
                helperText={
                  email && !validateEmail(email)
                    ? "Please enter a ID Number"
                    : ""
                }
                sx={{ marginBottom: 2 }}
                required
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Phone"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                error={phone && !validatePhone(phone)}
                helperText={
                  phone && !validatePhone(phone)
                    ? "Phone number must be 10 digits and start with 0."
                    : ""
                }
                sx={{ marginBottom: 2 }}
                required
              />
            </Grid2>
          </Grid2>
          <TextField
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={message && !validateMessage(message)}
            helperText={
              message && !validateMessage(message)
                ? "Message must be at least 10 characters long."
                : ""
            }
            required
          />
        </Box>
        {error && <Alert severity="warning">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Enquiry"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
