import React, { useEffect, useState } from "react";
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";
import { BarChart, PieChart } from "@mui/x-charts";

export default function RMDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch("/api/loans/risk-management", {
          credentials: "include",
        });
        const data = await response.json();

        setMetrics(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch("/api/loans/home");
        const d = await response.json();

        setData(d);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        setLoading(false); // Stop loading even in case of error
      }
    };

    fetchMetrics();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography variant="h5">No data available</Typography>;
  }

  return (
    <Box sx={{ mt: 8 }}>
      <Typography fontWeight="bold" color="primary" variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid2 container spacing={3}>
        <CardItem
          title="Total"
          value={
            metrics?.currentLoans
              ? metrics?.currentLoans +
                metrics?.pendingdLoans +
                metrics?.rejectedLoans
              : 0
          }
        />
        <CardItem title="Pending" value={metrics?.pendingdLoans ?? 0} />
        <CardItem title="Rejected" value={metrics?.rejectedLoans ?? 0} />
        <CardItem title="Active" value={metrics?.currentLoans ?? 0} />

        <Grid2 size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Loan Type</Typography>
              <BarChart
                xAxis={[
                  {
                    data: ["Active", "Rejected", "Pending"],
                    scaleType: "band",
                  },
                ]}
                colors={["blue", "orange", "green"]}
                series={[
                  {
                    data: [
                      metrics ? metrics?.currentLoanAmounts : 0,
                      metrics ? metrics?.rejectedLoanAmounts : 0,
                      metrics ? metrics?.pendingLoanAmounts : 0,
                    ],
                    label: "Loan Amounts",
                  },
                ]}
                height={400}
              />
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1" gutterBottom>
                Pending Loans
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#60606030",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: `${
                      (metrics?.verifiedPendingLoanAmount /
                        (metrics?.verifiedPendingLoanAmount +
                          metrics?.notVerifiedPendingLoanAmount)) *
                      100
                    }%`,
                    backgroundColor: "#0033A0",
                    color: "white",
                    p: 1,
                  }}
                  title={(
                    metrics?.verifiedPendingLoanAmount - 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                >
                  <Typography
                    mt={1}
                    textAlign="center"
                    display="block"
                    variant="h5"
                  >
                    {metrics?.verifiedPendingLoanAmount +
                      metrics?.notVerifiedPendingLoanAmount !=
                    0
                      ? (
                          (metrics?.verifiedPendingLoanAmount /
                            (metrics?.verifiedPendingLoanAmount +
                              metrics?.notVerifiedPendingLoanAmount)) *
                          100
                        ).toFixed(0)
                      : 0}
                    %
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: `${
                      (metrics?.notVerifiedPendingLoanAmount /
                        (metrics?.verifiedPendingLoanAmount +
                          metrics?.notVerifiedPendingLoanAmount)) *
                      100
                    }%`,
                    backgroundColor: "orange",
                    color: "white",
                    p: 1,
                  }}
                  title={(
                    metrics?.notVerifiedPendingLoanAmount - 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                >
                  <Typography
                    mt={1}
                    textAlign="center"
                    display="block"
                    variant="h5"
                  >
                    {metrics?.verifiedPendingLoanAmount +
                      metrics?.notVerifiedPendingLoanAmount !=
                    0
                      ? (
                          (metrics?.notVerifiedPendingLoanAmount /
                            (metrics?.verifiedPendingLoanAmount +
                              metrics?.notVerifiedPendingLoanAmount)) *
                          100
                        ).toFixed(0)
                      : 0}
                    %
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: "flex", gap: 1, width: "fit-content" }}>
                  <Box
                    sx={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "#0033A0",
                      margin: "auto",
                    }}
                  ></Box>
                  <Typography>
                    Premium -{" "}
                    {(metrics?.verifiedPendingLoanAmount - 0).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 0 }
                    )}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1, width: "fit-content" }}>
                  <Box
                    sx={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "orange",
                      margin: "auto",
                    }}
                  ></Box>
                  <Typography>
                    Regular -{" "}
                    {(metrics?.notVerifiedPendingLoanAmount - 0).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 0 }
                    )}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1" gutterBottom>
                Rejected Loans
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#60606030",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: `${
                      (metrics?.verifiedRejectedLoanAmount /
                        (metrics?.verifiedRejectedLoanAmount +
                          metrics?.notVerifiedRejectedLoanAmount)) *
                      100
                    }%`,
                    backgroundColor: "#0033A0",
                    color: "white",
                    p: 1,
                  }}
                  title={(
                    metrics?.verifiedRejectedLoanAmount - 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                >
                  <Typography
                    mt={1}
                    textAlign="center"
                    display="block"
                    variant="h5"
                  >
                    {metrics?.verifiedRejectedLoanAmount +
                      metrics?.notVerifiedRejectedLoanAmount !=
                    0
                      ? (
                          (metrics?.verifiedRejectedLoanAmount /
                            (metrics?.verifiedRejectedLoanAmount +
                              metrics?.notVerifiedRejectedLoanAmount)) *
                          100
                        ).toFixed(0)
                      : 0}
                    %
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: `${
                      (metrics?.notVerifiedRejectedLoanAmount /
                        (metrics?.verifiedRejectedLoanAmount +
                          metrics?.notVerifiedRejectedLoanAmount)) *
                      100
                    }%`,
                    backgroundColor: "orange",
                    color: "white",
                    p: 1,
                  }}
                  title={(
                    metrics?.notVerifiedRejectedLoanAmount - 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                >
                  <Typography
                    mt={1}
                    textAlign="center"
                    display="block"
                    variant="h5"
                  >
                    {metrics?.verifiedRejectedLoanAmount +
                      metrics?.notVerifiedRejectedLoanAmount !=
                    0
                      ? (
                          (metrics?.notVerifiedRejectedLoanAmount /
                            (metrics?.verifiedRejectedLoanAmount +
                              metrics?.notVerifiedRejectedLoanAmount)) *
                          100
                        ).toFixed(0)
                      : 0}
                    %
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: "flex", gap: 1, width: "fit-content" }}>
                  <Box
                    sx={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "#0033A0",
                      margin: "auto",
                    }}
                  ></Box>
                  <Typography>
                    Premium -{" "}
                    {(metrics?.verifiedRejectedLoanAmount - 0).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 0 }
                    )}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1, width: "fit-content" }}>
                  <Box
                    sx={{
                      height: "16px",
                      width: "16px",
                      backgroundColor: "orange",
                      margin: "auto",
                    }}
                  ></Box>
                  <Typography>
                    Regular -{" "}
                    {(
                      metrics?.notVerifiedRejectedLoanAmount - 0
                    ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = (props) => {
  return (
    <Grid2 size={{ md: 3, xs: 12, sm: 2 }}>
      <Card sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}>
        <CardContent>
          <Typography variant="subtitle" gutterBottom>
            {props.title}
          </Typography>
          <Typography color="primary" variant="h4" mt={2}>
            {props.value.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
};

const MyPieChart = (props) => {
  const pieParams = {
    height: 300,
    margin: { right: 5 },
    slotProps: {
      legend: {
        direction: "row",
        position: { vertical: "bottom", horizontal: "middle" },
        padding: 0,
        labelStyle: {
          fontSize: 12,
        },
      },
    },
  };

  // Directly use props.data without maintaining an internal state
  const mappedData = props.data.map((item, index) => ({
    id: index,
    label: item.label, // Using 'label' from props
    value: parseInt(item.value, 10), // Ensure 'value' is correctly parsed
    color: item.color, // Use color if needed
  }));

  return (
    <Card
      sx={{
        borderRadius: "12px",
        p: 2,
        boxShadow: "0px 10px 30px #60606040",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {props.title}
      </Typography>
      <PieChart
        series={[
          {
            data: mappedData, // Use the mapped data here
            outerRadius: 100,
            innerRadius: 20,
          },
        ]}
        {...pieParams}
      />
    </Card>
  );
};
